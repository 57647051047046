import React from 'react'
import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'

import { Section, Card } from './style'
import bestInvestmentsJSON from '../../assets/data/bestInvestments.json'

type IModalDataLayerProps = {
  sendDatalayerEvent: Function;
}

type bestInvestmentsProps = {
  title: string;
  description: string;
  cta: string;
  primaryTag: string;
  secundaryTag: string;
  url: string;
}

const TheBestContent = ({ sendDatalayerEvent }: IModalDataLayerProps) => {
  return (
    <Section className='py-5'>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <h2 className='fs-24 fs-lg-32 fs-xl-40 lh-28 lh-lg-38 lh-xl-48 text-grayscale--500 fw-700 text-md-center'>
              Acompanhe o blog do Inter
            </h2>
            <p className='fs-14 fs-lg-16 fs-xl-18 lh-17 lh-lg-20 lh-xl-22 text-grayscale--400 fw-400 mt-3 text-md-center'>
              Os melhores conteúdos para te ajudar a investir com segurança e rentabilidade.
            </p>
          </div>
        </div>
        <div className='row mt-3 justify-content-center'>
          {bestInvestmentsJSON.map((item: bestInvestmentsProps) => (
            <div key={item.title} className='col-12 col-md-4 mb-3'>
              <Card className='bg-white px-md-4 py-3 py-lg-4 flex-column'>
                <div className='d-flex flex-column d-lg-inline mb-3'>
                  <p className='fs-12 lh-16 fw-700 text-white bg-orange--extra rounded-1 mb-3 px-2 mr-2 primary-tag'>{item.primaryTag}</p>
                  <p className='fs-12 lh-16 fw-700 text-grayscale--500 bg-grayscale--100 rounded-1 mb-1 px-2 mr-2 secundary-tag'>{item.secundaryTag}</p>
                </div>
                <h3 className='fs-16 fs-lg-20 lh-20 lh-lg-24 lh-xl-24 text-grayscale--500 fw-400 mt-lg-2'>{item.title}</h3>
                <p className='fs-14 fs-xl-16 lh-17 lh-lg-16 lh-xl-20 text-grayscale--500 fw-400'>{item.description}</p>
                <div className='d-flex align-items-center justify-content-end'>
                  <p className='ler-mais text-orange--extra fw-700 mt-1 mb-0 mr-1'>
                    <a
                      target='blank'
                      onClick={() => {
                        sendDatalayerEvent({
                          section: 'dobra_7',
                          element_action: 'click button',
                          element_name: `${item.cta} - ${item.title}`,
                          section_name: 'Acompanhe o blog do Inter',
                          redirect_url: item.url,
                        })
                      }}
                      href={item.url}
                    >
                      {item.cta}
                    </a>
                  </p>
                  <OrangeIcon size='MD' color='#FF7A00' icon='arrow-right' />
                </div>
              </Card>
            </div>
          ))}
          <a
            href='https://blog.inter.co/para-simplificar-a-vida/investir/'
            target='blank'
            className='btn btn--lg text-primary--500 mt-3 btn-artigos mt-xl-5'
          >
            Acessar mais artigos
          </a>
        </div>
      </div>
    </Section>
  )
}

export default TheBestContent
