import React from 'react'
import ImageWebp from 'src/components/ImageWebp'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'

import { Section, Button, LinkMobile } from './style'

type IModalDataLayerProps = {
  sendDatalayerEvent: Function;
  setIsOpen: Function;
  setDataLayer: Function;
  isMobile: boolean;
  oneLink: string;
}

const NominatedToCOE = ({ setIsOpen, sendDatalayerEvent, setDataLayer, isMobile, oneLink  }: IModalDataLayerProps) => {
  
  const dobra02: IDataLayerParams = {
    section: 'dobra_02',
    section_name: 'Para quem é indicado o COE?',
    element_action: 'click button',
    element_name: 'Conhecer o COE',
  }

  const handleButtonClick = () => {
    setIsOpen(true)
    setDataLayer(dobra02)
    sendDatalayerEvent(dobra02)
  }

  return (
    <Section className='py-5 bg-gray-400 d-flex align-items-end align-items-md-center'>
      <div className='container'>
        <div className='row align-items-md-center'>
        <div className='col-12 col-md-6 col-lg-5 d-flex justify-content-center justify-content-md-start'>
          <ImageWebp
            arrayNumbers={[ 312, 336, 372, 456 ]}
            pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/grafismo3-coe/image.webp'
            altDescription='Pessoa sorrindo com roupa listrada e smartphone na mão.'
          />
        </div>
        <div className='col-12 col-md-6 col-lg-5 offset-lg-2 mt-2'>
          <h2 className='fs-24 fs-lg-32 fs-xl-40 lh-28 lh-xl-48 lh-md-38 text-grayscale--500 fw-700 mt-3'>
            Para quem é indicado o COE?
          </h2>
          <p className='fs-14 fs-lg-16 fs-xl-18 lh-17 lh-md-20 lh-xl-22 fw-400 text-grayscale--400 mt-3'>
            O COE é um produto estruturado indicado para investidores que buscam segurança na volatilidade do mercado. Amplie seus lucros com rentabilidade acima da média, de forma segura e eficiente, aproveitando alavancagem, capital protegido e remuneração fixa.
          </p>
          {
            isMobile ? (
              <LinkMobile
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_02',
                    element_action: 'click button',
                    element_name: 'Conhecer o COE',
                    section_name: 'Para quem é indicado o COE?',
                    redirect_url: oneLink,
                  })
                }}
                href={oneLink}
              >Conhecer o COE
              </LinkMobile>
            ) : (
              <Button
                onClick={handleButtonClick}
                title='Conhecer o COE'
                className='fs-14 lh-17 fw-600 text-orange--extra rounded-2 border-0 text-none'
              >
                Conhecer o COE
              </Button>
            )
          }
        </div>
      </div>
      </div>
    </Section>
  )
}

export default NominatedToCOE
