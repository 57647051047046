import React from 'react'
import ImageWebp from 'src/components/ImageWebp'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'
import useWidth from 'src/hooks/window/useWidth'
import { WIDTH_MD } from 'src/utils/breakpoints'

import { Section, Button, LinkMobile } from './style'

type IModalDataLayerProps = {
  sendDatalayerEvent: Function;
  setIsOpen: Function;
  setDataLayer: Function;
  isMobile: boolean;
  oneLink: string;
}

const Invest = ({ setIsOpen, sendDatalayerEvent, setDataLayer, isMobile, oneLink }: IModalDataLayerProps) => {
  const width = useWidth()

  const dobra05: IDataLayerParams = {
    section: 'dobra_05',
    section_name: 'Invista pelo SuperApp do Inter',
    element_action: 'click button',
    element_name: 'Investir',
  }

  const handleButtonClick = () => {
    setIsOpen(true)
    setDataLayer(dobra05)
    sendDatalayerEvent(dobra05)
  }

  return (
    <Section className='py-5 d-flex align-items-end align-items-md-center'>
      <div className='container'>
        <div className='row align-items-md-center'>
        {width >= WIDTH_MD && 
          <div className='col-12 col-md-6 col-lg-5 offset-lg-2 d-flex justify-content-center justify-content-md-start order-md-last'>
            <ImageWebp
              arrayNumbers={[ 312, 336, 372, 456 ]}
              pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/graficos-coe/image.webp'
              altDescription='Mão apontando para um gráfico na tela'
            />
          </div>
        }
        <div className='col-12 col-md-6 col-lg-5 mt-2'>
          <h2 className='fs-24 fs-lg-32 fs-xl-40 lh-28 lh-xl-48 lh-md-38 text-grayscale--500 fw-700 mt-3'>
            Invista pelo SuperApp do Inter
          </h2>
          <p className='fs-14 fs-lg-16 fs-xl-18 lh-17 lh-md-20 lh-xl-22 fw-400 text-grayscale--400 mt-3'>
            Descubra uma maneira simples, rápida e segura de investir com a plataforma de investimentos do Inter.
          </p>
          {
            isMobile ? (
              <LinkMobile
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_05',
                    element_action: 'click button',
                    element_name: 'Investir',
                    section_name: 'Invista pelo SuperApp do Inter',
                    redirect_url: oneLink,
                  })
                }}
                href={oneLink}
              >Investir
              </LinkMobile>
            ) : (
            <Button
              onClick={handleButtonClick}
              title='Investir'
              className='fs-14 lh-17 fw-600 text-orange--extra rounded-2 border-0 text-none'
            >
              Investir
            </Button>
          )
        }
        </div>
      </div>
      </div>
    </Section>
  )
}

export default Invest
