import React, { useState } from 'react'
import { Section, Investment, HeaderInvestment, LinkMobile } from './style'
import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import Pagination from './Pagination/_index'
import typesOfInvestmentsJSON from '../../assets/data/typesOfInvestments.json'

type TypeOfInvestmentsProps = {
  investment: string,
  sender: string,
  description: string,
  minimumApplication: string,
  unitPrice: string,
  degreeOfRisk: string,
  reservationDeadline: string,
  due: string,
  id: number
}

type IModalDataLayerProps = {
  sendDatalayerEvent: Function;
  setIsOpen: Function;
  setDataLayer: Function;
  isMobile: boolean;
  oneLink: string;
}

const TypeOfInvestments = ({ setIsOpen, sendDatalayerEvent, setDataLayer, isMobile, oneLink }: IModalDataLayerProps) => {
  const [ elementName, setElementName ] = useState()
  const [ activePage, setActivePage ] = useState(1)
  const [ offset, setOffset ] = useState(0)
  const limit = 2

  const handleButtonClick = (evt: MouseEvent<HTMLSpanElement>) => {
    setIsOpen(true)
    setElementName(evt)
    setDataLayer({
      section: 'dobra_4',
      section_name: 'Nossas operações',
      element_action: 'click button',
      element_name: `Reservar agora - ${evt}`,
    })
    sendDatalayerEvent({
      section: 'dobra_4',
      section_name: 'Nossas operações',
      element_action: 'click button',
      element_name: `Reservar agora - ${evt}`,
    })
  }

  return (
    <Section className='py-5 bg-gray-400'>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <h2 className='fs-24 fs-lg-32 fs-xl-40 lh-28 lh-lg-38 lh-xl-48 text-grayscale--500 fw-700 text-md-center'>
              Nossas operações
            </h2>
            <p className='fs-14 fs-lg-16 fs-xl-18 lh-17 lh-lg-20 lh-xl-22 text-grayscale--400 text-md-center mt-md-3'>
              <span className='d-xl-block'><span className='d-lg-block d-xl-inline'>Os produtos estruturados oferecem a rentabilidade da renda variável com a</span> segurança</span> da renda fixa, sendo mais estável e flexível em diferentes cenários. 
            </p>
          </div>
        </div>
        <div className='row justify-content-center mt-md-3'>
          {typesOfInvestmentsJSON.slice(offset, offset + limit).map((item: TypeOfInvestmentsProps) => (
            <div key={item.id} className='col-12 col-md-6 col-lg-3'>
              <Investment className='px-3 py-3 mb-4 mr-md-1 bg-white'>
                <HeaderInvestment className='mb-3'>
                  <h3 className='fs-16 fs-xl-20 lh-20 text-grayscale--500 fw-500'>{item.investment}</h3>
                  <p className='fs-14 lh-16 text-grayscale--400'>{item.description}</p>
                </HeaderInvestment>
                <div className='d-flex justify-content-between flex-lg-column justify-content-lg-start justify-content-xl-between'>
                  <p className='fs-12 lh-14 text-grayscale--400 fw-400 d-lg-block d-xl-inline mb-md-1'>Emissor</p>
                  <p className='fs-12 lh-14 text-grayscale--500 fw-700'>{item.sender}</p>
                </div>
                <div className='d-flex justify-content-between flex-lg-column justify-content-lg-start justify-content-xl-between'>
                  <p className='fs-12 lh-14 text-grayscale--400 fw-400 d-lg-block d-xl-inline mb-md-1'>Aplicação mínima</p>
                  <p className='fs-12 lh-14 text-grayscale--500 fw-700'>{item.minimumApplication}</p>
                </div>
                <div className='d-flex justify-content-between flex-lg-column justify-content-lg-start justify-content-xl-between'>
                  <p className='fs-12 lh-14 text-grayscale--400 fw-400 d-lg-block d-xl-inline mb-md-1'>Preço unitário</p>
                  <p className='fs-12 lh-14 text-grayscale--500 fw-700'>{item.unitPrice}</p>
                </div>
                <div className='d-flex justify-content-between flex-lg-column justify-content-lg-start justify-content-xl-between'>
                  <p className='fs-12 lh-14 text-grayscale--400 fw-400 d-lg-block d-xl-inline mb-md-1'>Grau de risco</p>
                  <p className='fs-12 lh-14 text-grayscale--500 fw-700'>{item.degreeOfRisk}</p>
                </div>
                <div className='d-flex justify-content-between flex-lg-column justify-content-lg-start justify-content-xl-between'>
                  <p className='fs-12 lh-14 text-grayscale--400 fw-400 d-lg-block d-xl-inline mb-md-1'>Prazo de reserva</p>
                  <p className='fs-12 lh-14 text-grayscale--500 fw-700'>{item.reservationDeadline}</p>
                </div>
                <div className='d-flex justify-content-between flex-lg-column justify-content-lg-start justify-content-xl-between'>
                  <p className='fs-12 lh-14 text-grayscale--400 fw-400 d-lg-block d-xl-inline mb-0 mb-md-1'>Vencimento</p>
                  <p className='fs-12 lh-14 text-grayscale--500 fw-700 mb-0'>{item.due}</p>
                </div>
                <div className='d-flex align-items-center justify-content-end mt-2'>
                  {
                    isMobile ? (
                      <LinkMobile
                        className='fs-12 lh-14 text-orange--extra fw-700 mt-3 mb-0 mr-2 cursor-pointer'
                        onClick={() => {
                          sendDatalayerEvent({
                            section: 'dobra_04',
                            element_action: 'click button',
                            element_name: `Reservar agora - ${item.investment}`,
                            section_name: 'Nossas Operações',
                            redirect_url: oneLink,
                          })
                        }}
                        href={oneLink}
                      >Reservar agora
                      </LinkMobile>
                    ) : (
                      <span
                        onClick={(evt: MouseEvent<HTMLSpanElement>) => {
                          setIsOpen(true)
                          handleButtonClick(item.investment)
                        }}
                        title='Conhecer o COE'
                        className='fs-12 lh-14 text-orange--extra fw-700 mt-3 mb-0 mr-2 cursor-pointer'
                      >
                        Reservar agora
                      </span>
                    )
                  }
                  <OrangeIcon className='mt-3' size='SM' color='#FF7A00' icon='arrow-right' />
                </div>
              </Investment>
            </div>
          ))}
          {typesOfInvestmentsJSON.length > 2 && <Pagination
            activePage={activePage}
            setActivePage={setActivePage}
            numberPages={Math.ceil(typesOfInvestmentsJSON.length / limit)}
            limit={limit}
            setOffset={setOffset}
          />}
        </div>
      </div>
    </Section>
  )
}

export default TypeOfInvestments
