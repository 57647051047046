import { device } from 'src/styles/breakpoints'
import { grayscale, green, orange } from 'src/styles/colors'
import styled from 'styled-components'

type LangProps = {
  typeLang?: string;
}

export const PaginationStyle = styled.nav<LangProps>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  
  button {
    cursor: pointer;
    border: none;
    background-color: transparent;
    outline: none;
    padding: 0;
  }
  > button {
      width: 45.33px;
      height: 48px;

      @media ${device.tablet} {
        width: 48px;
        height: 48px;
      }

    &.arrows {
      position: relative;
      top: -4px;
    }
  }
  ul {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 10px 10px 10px;
    list-style: none;

    li {
      margin-right: 2px;

      &:last-child {
        margin-right: 0;
      }

      @media ${device.tablet} {
        margin-right: 16px;

      }

      button {
        width: 45.33px;
        height: 48px;
        color: ${grayscale[500]};
        font-size: 18px;
        font-weight: 700;

        @media ${device.tablet} {
          width: 48px;
          height: 48px;
        }
        
        &.active {
          border-radius: 8%;
          background-color: ${(props: LangProps) => props.typeLang === 'PJ' ? green[500] : orange.extra };
          color: white;
        }
      }
    }
  }
`
