import React from 'react'
import Link from 'src/components/GatsbyLinkWrapper/index'
import Home from '@interco/icons/build-v4/orangeds/MD/home'
import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import headerJson from '../../assets/data/breadcrumb.json'

import { Section, Button, LinkMobile } from './style'

type IModalDataLayerProps = {
  sendDatalayerEvent: Function;
  setIsOpen: Function;
  setDataLayer: Function;
  dobra01: string;
  isMobile: boolean;
  oneLink: string;
}

const Hero = ({ setIsOpen, sendDatalayerEvent, setDataLayer, dobra01, isMobile, oneLink }: IModalDataLayerProps) => {
  const handleButtonClick = () => {
    setIsOpen(true)
    setDataLayer(dobra01)
    sendDatalayerEvent(dobra01)
  }

  return (
    <Section className='py-5 d-flex align-items-end align-items-md-center'>
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-md-6 col-lg-5'>
            <div className='d-md-block text-grayscale--500'>
              <Link
                to='/'
                className='d-md-inline'
              ><Home height={24} width={24} color='#6A6C72' />
              </Link>
              <OrangeIcon className='d-md-inline mx-2' icon='arrow-right' color='#6A6C72' size='SM' />
              <Link
                to='/pra-voce/investimentos/'
                className='fs-12 fs-lg-14 lh-14 fw-700 text-grayscale--400 d-inline mr-2'
              > 
                {headerJson.breadcrumb[0]}
              </Link>
              <OrangeIcon icon='arrow-right' color='#6A6C72' size='SM' />
              <p className='fs-12 fs-lg-14 lh-14 fw-700 text-grayscale--400 d-inline ml-2 mb-0'>{headerJson.breadcrumb[1]}</p>
            </div>
            <h1 className='fs-24 fs-lg-32 fs-xl-40 lh-28 lh-lg-38 text-grayscale--500 fw-700 mt-3'>
              COE - Certificado de Operações Estruturadas
            </h1>
            <p className='fs-14 fs-lg-16 fs-xl-18 lh-17 lh-lg-20 lh-xl-22 fw-400 text-grayscale--400 mt-3'>
              Investir em COE é uma estratégia poderosa que combina ativos de renda fixa e variável, como títulos emitidos por instituições financeiras com ações, índices, moedas estrangeiras ou commodities. Diversifique seus investimentos mitigando riscos e maximizando seus resultados. 
            </p>
            {
              isMobile ? (
                <LinkMobile
                  onClick={() => {
                    sendDatalayerEvent({
                      section: 'dobra_01',
                      element_action: 'click button',
                      element_name: 'Saber mais',
                      section_name: 'COE - Certificado de Operações Estruturadas',
                      redirect_url: oneLink,
                    })
                  }}
                  href={oneLink}
                >Saber mais
                </LinkMobile>
              ) : (
                <Button
                  onClick={handleButtonClick}
                  title='Saber mais'
                  className='fs-14 lh-17 fw-600 text-orange--extra rounded-2 border-0 text-none'
                >
                  Saber mais
                </Button>
              )
            }
          </div>
        </div>
      </div>
    </Section>
  )
}

export default Hero
