import React from 'react'
import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import { DisclaimerDiv } from './style'

const Attention = () => {

  return (
    <DisclaimerDiv className='py-5'>
      <div className='container'>
        <div className='row'>
          <div className='col-12 align-items-center d-flex'>
            <div className='icon'>
              <OrangeIcon size='MD' color='#FF7A00' icon='attention' />
            </div>
            <div>
              <p className='fs-16 lh-19 fw-400 text-grayscale--400 mb-0'>
                Este material foi preparado pelo Inter com o objetivo de informar os investidores, não constituindo uma oferta de compra ou venda de títulos ou valores mobiliários. Os produtos apresentados podem não ser adequados para todos os investidores e não levam em consideração seus objetivos, situação financeira e necessidades específicas. Investimentos nos mercados financeiros estão sujeitos a riscos de perda de capital, e a rentabilidade passada não garante resultados futuros. Investimentos em ações não contam com a garantia do Fundo Garantidor de Crédito - FGC. Antes de investir, verifique as características do produto e invista de acordo com seu perfil de investimentos. 
              </p>
            </div>
          </div>
        </div>
      </div>
    </DisclaimerDiv>
  )
}

export default Attention
