import React from 'react'
import ImageWebp from 'src/components/ImageWebp'
import OpenVideo from 'src/components/OpenVideo'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'

import { Section, Button, LinkMobile } from './style'
import PlayVideo from '../../assets/images/play-video.png'

type UnderstandMoreProps = {
  sendDatalayerEvent: Function;
  setIsOpen: Function;
  setDataLayer: Function;
  isMobile: boolean;
  oneLink: string;
}

const UnderstandMore = ({ setIsOpen, sendDatalayerEvent, setDataLayer, isMobile, oneLink }: UnderstandMoreProps) => {
  const dobra06: IDataLayerParams = {
    section: 'dobra_06',
    section_name: 'Entenda mais sobre as operações estruturadas',
    element_action: 'click button',
    element_name: 'Conhecer o COE',
  }

  const handleButtonClick = () => {
    setIsOpen(true)
    setDataLayer(dobra06)
    sendDatalayerEvent(dobra06)
  }

  return (
    <Section className='py-5 bg-gray'>
      <div className='container'>
        <div className='row align-items-center'>
          <div className='col-12 col-md-6 col-lg-5 d-flex justify-content-center'>
            <OpenVideo
              link='https://www.youtube.com/embed/pVcQ-4c2_QQ?si=X7uFyO4g0BFjU95Q'
              onClick={() => sendDatalayerEvent({
                section: 'dobra_6',
                element_action: 'click video',
                element_name: 'Assistir Vídeo',
                section_name: 'Entenda mais sobre as operações estruturadas',
                redirect_url: 'https://www.youtube.com/embed/pVcQ-4c2_QQ?si=X7uFyO4g0BFjU95Q',
              })}
            >
              <div className='play cursor-pointer'>
                <img src={PlayVideo} alt='Assistir Vídeo' />
              </div>
              <ImageWebp
                arrayNumbers={[ 312, 336, 372, 456 ]}
                arrayNumbersHeight={[ 312, 336, 372, 456 ]}
                pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/grafismo2-coe/image.webp'
                altDescription='Joe Ruas, especialista em investimentos do Inter de roupa branca com detalhes laranja falando sobre o COE.'
              />
            </OpenVideo>
          </div>
          <div className='col-12 col-md-6 col-lg-5 offset-lg-2 mt-3 mt-md-0'>
            <h2 className='fs-24 fs-lg-32 fs-xl-40 lh-28 lh-lg-38 lh-xl-48 text-grayscale--500 fw-700'>
              Entenda mais sobre as operações estruturadas
            </h2>
            <p className='fs-14 fs-lg-16 fs-xl-18 lh-17 lh-lg-20 lh-xl-22 text-grayscale--400 mt-3'>O COE permite diferentes estruturas de produto e essa combinação é o que proporciona a expectativa de lucro e a segurança do investidor.</p>
            {
              isMobile ? (
                <LinkMobile
                  onClick={() => {
                    sendDatalayerEvent({
                      section: 'dobra_06',
                      element_action: 'click button',
                      element_name: 'Conhecer o COE',
                      section_name: 'Entenda mais sobre as operações estruturadas',
                      redirect_url: oneLink,
                    })
                  }}
                  href={oneLink}
                >Conhecer o COE
                </LinkMobile>
              ) : (
                <Button
                  onClick={handleButtonClick}
                  title='Conhecer o COE'
                  className='fs-14 lh-17 fw-600 text-orange--extra rounded-2 border-0 text-none'
                >
                  Conhecer o COE
                </Button>
            )
          }
          </div>
        </div>
      </div>
    </Section>
  )
}

export default UnderstandMore
